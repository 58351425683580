<template>
  <div class="power">
    <div class="text-h5 text--primary d-flex pa-2 font-weight-bold"> 세력주 </div>
    <div class="pl-2 d-flex justify-start"> 
      거래량이 직전 240일 이동평균 거래량보다 10배 이상 폭등하고 20% 저점에서 턴어라운드한 종목 (직전거래일 종가 기준)
    </div>
    <br>
    <div class="d-flex justify-end mb-6"> 
      <v-btn   
        text
        color="primary"
        small 
        class="font-weight-bold"
        to="/power/account"
      >
        <v-icon left> mdi-currency-usd </v-icon> 세력주 평가금액
      </v-btn>
    </div>
    
    <v-data-table
      :headers="headers"
      :items="powerList"
      :items-per-page="10"
      class="elevation-1"
      :loading="loadTable"   
      loading-text="검색 중입니다. 잠시만 기다려 주세요."
    > 
      <template #item.name="{ item }">
        <a target="_blank" :href="item.link">
          {{ item.name }} ({{ item.code }})
        </a>
      </template>
      <template #item.chart="{ item }">
        <v-icon @click="get_chart(item.code, item.name, item.date)">mdi-finance</v-icon>
      </template>
      <template #item.mavg_volume="{ item }">        
        {{  Number(item.mavg_volume).toLocaleString() }}
      </template>
      <template #item.volume="{ item }">        
        {{  Number(item.volume).toLocaleString() }}
      </template>
      <template #item.open="{ item }">        
        {{  Number(item.open).toLocaleString() }}
      </template>
      <template #item.high="{ item }">        
        {{  Number(item.high).toLocaleString() }}
      </template>
      <template #item.low="{ item }">        
        {{  Number(item.low).toLocaleString() }}
      </template>
      <template #item.close="{ item }">        
        {{  Number(item.close).toLocaleString() }}
      </template>
    </v-data-table>

    <!-- 이동평군선 차트 -->
    <br><br>
    <div v-if="showChart" class="text-h6" align="start"> {{ selectedName }} 240일 거래량 </div>
    <br>
    <v-card v-if="showChart">
      <template>
        <div class="container">
        <bar-chart
          :chartData="datacollection"
          :options="chart_options"
          :height="200" 
        />        
        </div>
      </template>
    </v-card>


    <!-- 알림 메세지 -->
    <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
    >
    {{ snackText }}
    <template v-slot:action="{ attrs }">
        <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
        >
        Close
        </v-btn>
    </template>
</v-snackbar>

  </div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import BarChart from '../components/BarChart.js';
  
  export default {
    components: { BarChart },
    data () {
      return {
        loadTable: false,
        showChart: false,
        powerList: [],
        headers: [
          { text: '번호', value: 'seqno', align: 'start' },
          { text: '거래일', value: 'date', align: 'start' },          
          { text: '종목명', value: 'name', align: 'start' },          
          { text: '240일 평균거래량', value: 'mavg_volume', align: 'end' },
          { text: '거래량', value: 'volume', align: 'end' },
          { text: '시가', value: 'open', align: 'end' },
          { text: '고가', value: 'high', align: 'end' },
          { text: '저가', value: 'low', align: 'end' },
          { text: '종가', value: 'close', align: 'end' },
          { text: '차트', value: 'chart', align: 'end' },
        ],

        datacollection: null,
        chart_options: {
          title: {
            display: false,
            text: '240일 이동평균선',
          },
          scales: {
            xAxes: [{
                gridLines: { drawOnChartArea: false }
            }],
            yAxes: [{
                gridLines: { drawOnChartArea: true }
            }]
          },
          legend: {
            display: true,
          },
        },

        selectedName: null,

        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,
      }
    },

    computed: {
      ...mapState([ 'isLogin', 'debug', 'user' ])
    },

    created() {
      this.get_power_list();
    },

    methods: {
      get_power_list() {
        var vm = this;
        this.loadTable = true;
        const req_data = {'user': this.user}
        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
        axios.post('/api/power/list/', req_data, {headers})
          .then(function(res) {
            vm.powerList = res.data;
            vm.loadTable = false;
          })
          .catch(function (err) {
            vm.snackbar = true;
            vm.snackColor = 'error';
            vm.snackText = err;            
          });
      },

      get_chart(code, name, date) {
        this.showChart = true;
        this.selectedName = name;
        var vm = this;
        const req_data = {'user': this.user, 'stock_code': code, 'date': date}
        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')}; 
        axios.post('/api/power/chart/', req_data, {headers})
          .then(function(res) {
            var v_labels = res.data['label_data'];
            var v_bar_data = res.data['bar_data'];
            var v_line_data = res.data['line_data'];
            // 240 day moving average chart
            var v_bar_dataset = { type: 'bar', label: '거래량', data: v_bar_data, borderColor: 'orange', pointRadius: 0, backgroundColor: '#FFE0B2', fill: false};
            var v_line_dataset = { type: 'line', label: '240일 이평거래량', data: v_line_data, borderColor: 'blue', pointRadius: 0, backgroundColor: '#FFE0B2', fill: false};
            var v_datasets = []; 
            v_datasets.push(v_bar_dataset)
            v_datasets.push(v_line_dataset)
            vm.datacollection = { labels: v_labels, datasets: v_datasets};                
          })
          .catch(function (err) {
            vm.snackbar = true;
            vm.snackColor = 'error';
            vm.snackText = err;            
          });
      },
    }
  }
</script>

<style>
.power{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>
